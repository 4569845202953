import {
  AdBreak,
  Advert,
  BreakType,
  DebugFlags,
  PlayerEvent,
  Session,
  SessionErrorCode,
  SessionLive,
  SessionProperties,
  SessionState,
  SessionVOD,
  TimedMetadata,
  YoLog,
} from "@yospace/admanagement-sdk";

import type { TrackingError } from "@yospace/admanagement-sdk/types/Public/TrackingError";

import { isNumber, msToSeconds, secondsToMs } from "../../../../utils";
import { Id3Cue } from "../../../Types";
import {
  getStitchedUrl,
  getUniversalAdId,
  isVodSession,
  mapBreakType,
  parseAdInfoExtension,
  parseYospaceTimedDataObjectFromId3Cues,
} from "./utils";
import {
  YospaceError,
  YospaceErrorCodes,
  YospaceLoadError,
} from "./YospaceError";
import {
  Advertisement,
  AdvertisementAuxiliaryData,
  AdvertisementBreak,
} from "./dtos";
import { XmlNode } from "@yospace/admanagement-sdk/types/Core/XmlNode";

export interface IYospaceCallbacks<
  TAdvertisementAuxiliaryData extends Record<string, any>
> {
  advertisementBreakStart: (
    advertisementBreak?: AdvertisementBreak<TAdvertisementAuxiliaryData>
  ) => void;
  advertisementBreakEnd: (
    advertisementBreak?: AdvertisementBreak<TAdvertisementAuxiliaryData>
  ) => void;
  advertisementBreaksUpdated: (
    newAdvertisementBreaks: Array<
      AdvertisementBreak<TAdvertisementAuxiliaryData>
    >
  ) => void;
  advertisementStart: (
    advertisement?: Advertisement<TAdvertisementAuxiliaryData>
  ) => void;
  advertisementEnd: (
    advertisement?: Advertisement<TAdvertisementAuxiliaryData>
  ) => void;
}

export interface IYospaceParameters {
  "yo.av"?: 3 | 4;
  "yo.br"?: boolean;
  "yo.brp"?: boolean;
  "yo.cda"?: boolean;
  /**
   * Note: must follow the format:
   *  Revision a:
   *   <revision>.<stream_behaviour>.<tracking_mode>.<PDT_mode>.<playlist_size>.<playback_start_pos>.<remnant_resolution>
   *   Example: "a.mw.p.s.32.3p.rp"
   *  Revision b:
   *   <revision>.<stream_behaviour>.<tracking_mode>.<PDT_mode>.<playlist_size>.<playback_start_pos>.<time_unit>.<remnant_resolution>
   *   Example: "b.mw.p.s.32.3p.td.rp"
   */
  "yo.cps"?: string;
  "yo.dr"?: boolean;
  "yo.js"?: boolean;
  "yo.lp"?: boolean;
  "yo.lpa"?: boolean | "dur";
  "yo.nl"?: boolean | "none";
  "yo.p.fo"?: boolean | "video-short" | "video-long" | "force";
  "yo.p.md"?: "pps";
  "yo.p.vad"?: boolean;
  "yo.pbr"?: boolean;
  "yo.pdt"?: boolean | "sync";
  "yo.po"?: boolean;
  "yo.pst"?: boolean;
  "yo.so"?: number;
  "yo.vp"?: boolean;
}

export interface IYospaceConfiguration<
  TAdvertisementAuxiliaryData extends Record<string, any>
> {
  allowDelayedMetadataDelivery: boolean;
  allowStartInReplacedLiveBreak: boolean;
  allowStartInReplacedOnDemandBreak: boolean;
  analyticsMode?: 3 | 4;
  callbacks: IYospaceCallbacks<TAdvertisementAuxiliaryData>;
  contentId: string;
  contentUrl: string;
  debug?: boolean;
  extraParameters?: Record<string, string>;
  live: boolean;
  liveAdvertisementBreakHistoryLimit: number;
  startOver: boolean;
  startTime?: number;
  userAgent?: string;
  yospaceParameters?: IYospaceParameters;

  parseExtensions?: (
    extensionsNode: XmlNode | null
  ) => TAdvertisementAuxiliaryData | undefined;
}

export class Yospace<
  TAdvertisementAuxiliaryData extends AdvertisementAuxiliaryData = {}
> {
  private readonly configuration: IYospaceConfiguration<TAdvertisementAuxiliaryData>;
  private readonly sessionProperties: SessionProperties;

  private absolutePosition: number = 0;
  private advertisement?: Advertisement<TAdvertisementAuxiliaryData>;
  private advertisementBreak?: AdvertisementBreak<TAdvertisementAuxiliaryData>;
  private advertisementBreaks: Array<
    AdvertisementBreak<TAdvertisementAuxiliaryData>
  > = [];
  private advertisementStartedAtPosition?: number;
  private isBuffering: boolean = false;
  private nextAdvertisementId: number = -1;
  private nextAdvertisementBreakId: number = -1;
  private session?: Session;
  private stitchedUrl?: string;
  private yospaceTimedDataObjects: Array<TimedMetadata> = [];

  constructor(
    configuration: IYospaceConfiguration<TAdvertisementAuxiliaryData>
  ) {
    this.configuration = configuration;
    this.sessionProperties = new SessionProperties();
    const userAgent = this.configuration.userAgent ?? navigator?.userAgent;
    if (userAgent) {
      this.sessionProperties.setUserAgent(userAgent);
    }

    if (this.configuration.debug) {
      YoLog.setDebugFlags(DebugFlags.DEBUG_ALL); // enable yospace debugging
    } else {
      YoLog.setLogger({
        debug: () => {},
        error: () => {},
        info: () => {},
        warn: () => {},
      });
    }
  }

  private cloneAdvertisementBreaks(
    advertisementBreaks: Array<AdvertisementBreak<TAdvertisementAuxiliaryData>>
  ): Array<AdvertisementBreak<TAdvertisementAuxiliaryData>> {
    return advertisementBreaks.map<
      AdvertisementBreak<TAdvertisementAuxiliaryData>
    >(
      (advertisementBreak) =>
        ({
          ...advertisementBreak,
          advertisements: advertisementBreak.advertisements.map(
            (advertisement) => ({
              ...advertisement,
            })
          ),
        } as AdvertisementBreak<TAdvertisementAuxiliaryData>)
    );
  }

  private async createYospaceSession(yospaceUrl: string): Promise<Session> {
    const session: Session = await (this.configuration.live
      ? SessionLive.create(yospaceUrl, this.sessionProperties)
      : SessionVOD.create(yospaceUrl, this.sessionProperties));

    if (!session) {
      throw new YospaceLoadError(
        SessionState.NONE,
        -1,
        YospaceErrorCodes.FailedToCreateSession
      );
    }

    const sessionState = session.getSessionState();
    const resultCode = session.getResultCode();

    if (sessionState !== SessionState.INITIALISED) {
      await this.destroy();

      throw new YospaceLoadError(sessionState, resultCode);
    }

    this.stitchedUrl = getStitchedUrl(session.getPlaybackUrl());
    if (!this.stitchedUrl) {
      throw new YospaceError(YospaceErrorCodes.StitchingFailed);
    }

    session.addAnalyticObserver({
      onAdvertBreakEnd: (s: Session) =>
        this.configuration.callbacks.advertisementBreakEnd(
          this.handleAdvertisementBreakEnd()
        ),
      onAdvertBreakStart: (adBreak: AdBreak, s: Session) =>
        this.configuration.callbacks.advertisementBreakStart(
          this.handleAdvertisementBreakStart(adBreak)
        ),
      onAdvertBreakEarlyReturn: (adBreak: AdBreak, s: Session) => {},
      onAdvertEnd: (s: Session) =>
        this.configuration.callbacks.advertisementEnd(
          this.handleAdvertisementEnd()
        ),
      onAdvertStart: (advert: Advert, s: Session) =>
        this.configuration.callbacks.advertisementStart(
          this.handleAdvertisementStart(advert)
        ),
      onAnalyticUpdate: (s: Session) => this.mapAdvertisementBreaks(s),
      onTrackingEvent: (type: string, s: Session) => {},
      onSessionError: (errorCode: SessionErrorCode, s: Session) => {
        this.configuration.debug &&
          console.error("Yospace::SessionError:", errorCode);
      },
      onTrackingError: (trackingError: TrackingError, s: Session) => {
        this.configuration.debug &&
          console.error("Yospace::TrackingError:", trackingError);
      },
    });

    if (isVodSession(session)) {
      this.mapAdvertisementBreaks(session);

      if (isNumber(this.configuration.startTime)) {
        session.setAdBreaksInactivePriorTo(
          secondsToMs(this.configuration.startTime)
        );
      }
    }

    return session;
  }

  private createYospaceUrl(
    configuration: IYospaceConfiguration<TAdvertisementAuxiliaryData>
  ): string {
    const yospaceUrl = new URL(configuration.contentUrl);

    // First set any raw yospace parameters defined
    if (configuration.yospaceParameters) {
      Object.entries(configuration.yospaceParameters).forEach((entry) => {
        yospaceUrl.searchParams.set(entry[0], "" + entry[1]);
      });
    }

    // Then enforce features & limitations

    const av = yospaceUrl.searchParams.get("yo.av");
    const parsedAv = parseInt(av ?? "0", 10);
    if (parsedAv < 3 || parsedAv > 4) {
      // yo.av 3 or 4 are the only values supported in this integration
      yospaceUrl.searchParams.set(
        "yo.av",
        configuration.analyticsMode?.toString() ?? "3"
      );
    }

    if (
      (this.configuration.live &&
        !this.configuration.allowStartInReplacedLiveBreak) ||
      (!this.configuration.live &&
        !this.configuration.allowStartInReplacedOnDemandBreak)
    ) {
      yospaceUrl.searchParams.set("yo.br", "false");
    }

    const md = yospaceUrl.searchParams.get("yo.p.md");
    if (md && !this.configuration.allowDelayedMetadataDelivery) {
      yospaceUrl.searchParams.delete("yo.p.md");
    }

    if (configuration.startOver && configuration.startTime) {
      yospaceUrl.searchParams.set("yo.so", configuration.startTime.toString());

      // Using yospace startOver, they will "shift" the manifest server-side so that it will start at 0 at the requested time
      configuration.startTime = 0;
    }

    if (configuration.extraParameters) {
      const extraParameters = configuration.extraParameters;
      Object.keys(extraParameters).forEach((key) => {
        yospaceUrl.searchParams.set(key, extraParameters[key]);
      });
    }

    return yospaceUrl.href;
  }

  private findAdvertisementByCorrelationId(
    advertisementBreak?: AdvertisementBreak<TAdvertisementAuxiliaryData>,
    correlationId?: string
  ): Advertisement<TAdvertisementAuxiliaryData> | undefined {
    if (!advertisementBreak || !correlationId) return;

    return advertisementBreak.advertisements.find(
      (a) => a.correlationId === correlationId
    );
  }

  private findAdvertisementBreak(
    adBreak?: AdBreak | null
  ): AdvertisementBreak<TAdvertisementAuxiliaryData> | undefined {
    if (!adBreak) return;

    if (adBreak.getIdentifier())
      return this.findAdvertisementBreakByCorrelationId(
        adBreak.getIdentifier()
      );

    if (isVodSession(this.session)) {
      if (isNumber(adBreak.getStart()))
        return this.findAdvertisementBreakByPosition(
          msToSeconds(adBreak.getStart())
        );
    } else if (this.session) {
      return this.findAdvertisementBreakByAdvertisementCorrelationIds(
        adBreak.getAdverts().map((a) => a.getIdentifier())
      );
    }
  }

  private findAdvertisementBreakByAdvertisementCorrelationIds(
    advertisementCorrelationIds: Array<string>
  ): AdvertisementBreak<TAdvertisementAuxiliaryData> | undefined {
    return this.findAdvertisementBreakByPredicate((advertisementBreak) => {
      /**
       * Note: This function is currently only used in a live scenario as a fallback for when the break does not have
       * an identifier. In this scenario we do not want to match already watched breaks as we could miss adding a
       * newly discovered break then.
       */
      if (advertisementBreak.watched) return false;

      if (
        advertisementCorrelationIds.length !==
        advertisementBreak.advertisements.length
      )
        return false;

      for (let i = 0; i < advertisementCorrelationIds.length; i++) {
        if (
          advertisementCorrelationIds[i] !==
          advertisementBreak.advertisements[i].correlationId
        )
          return false;
      }

      return true;
    });
  }

  private findAdvertisementBreakByCorrelationId(
    correlationId: string
  ): AdvertisementBreak<TAdvertisementAuxiliaryData> | undefined {
    return this.findAdvertisementBreakByPredicate(
      (advertisementBreak) => advertisementBreak.correlationId === correlationId
    );
  }

  private findAdvertisementBreakByPosition(
    position: number
  ): AdvertisementBreak<TAdvertisementAuxiliaryData> | undefined {
    return this.findAdvertisementBreakByPredicate(
      (advertisementBreak) => advertisementBreak.position === position
    );
  }

  private findAdvertisementBreakByPredicate(
    predicate: (
      advertisementBreak: AdvertisementBreak<TAdvertisementAuxiliaryData>
    ) => boolean
  ): AdvertisementBreak<TAdvertisementAuxiliaryData> | undefined {
    return this.advertisementBreaks.find(predicate);
  }

  private handleAdvertisementEnd():
    | Advertisement<TAdvertisementAuxiliaryData>
    | undefined {
    const advertisement = this.advertisement;
    this.advertisement = undefined;
    this.advertisementStartedAtPosition = undefined;

    return advertisement
      ? {
          ...advertisement,
        }
      : undefined;
  }

  private handleAdvertisementStart(
    advert?: Advert
  ): Advertisement<TAdvertisementAuxiliaryData> | undefined {
    const activeAdvert = advert ?? this.session?.getCurrentAdvert();
    if (!this.session || !activeAdvert || !activeAdvert.getLinearCreative()) {
      return;
    }

    const adBreak = this.session.getCurrentAdBreak();
    const advertisementBreak =
      this.advertisementBreak ?? this.findAdvertisementBreak(adBreak);
    const knownAdvertisement = this.findAdvertisementByCorrelationId(
      advertisementBreak,
      activeAdvert.getIdentifier()
    );

    this.advertisement =
      knownAdvertisement ?? this.mapAdvertisement(activeAdvert);
    this.advertisementStartedAtPosition = this.absolutePosition;

    return this.advertisement;
  }

  private handleAdvertisementBreakEnd():
    | AdvertisementBreak<TAdvertisementAuxiliaryData>
    | undefined {
    if (this.advertisementBreak) {
      this.advertisementBreak.active = false;
      this.advertisementBreak.watched = true;
    }

    const advertisementBreak = this.advertisementBreak;
    this.advertisementBreak = undefined;

    return advertisementBreak
      ? ({
          ...advertisementBreak,
          advertisements: advertisementBreak.advertisements.map(
            (advertisement) => ({
              ...advertisement,
            })
          ),
        } as AdvertisementBreak<TAdvertisementAuxiliaryData>)
      : undefined;
  }

  private handleAdvertisementBreakStart(
    adBreak?: AdBreak | null
  ): AdvertisementBreak<TAdvertisementAuxiliaryData> | undefined {
    adBreak = adBreak ?? this.session?.getCurrentAdBreak();

    const knownAdvertisementBreak = this.findAdvertisementBreak(adBreak);

    let advertisementBreak = knownAdvertisementBreak;
    if (!advertisementBreak && adBreak) {
      /**
       * Note: Overriding position when mapping a newly discovered break (live) as it is incorrect from the
       * yospace library for live breaks
       */
      advertisementBreak = this.mapAdvertisementBreak(
        adBreak,
        this.absolutePosition
      );
    }

    // If we don't know anything about the advertisement break at this point, just return
    if (!advertisementBreak) return;

    this.advertisementBreak = advertisementBreak;
    this.advertisementBreak.active = true;

    if (!knownAdvertisementBreak) {
      this.advertisementBreaks.push(advertisementBreak);

      if (
        this.configuration.live &&
        this.advertisementBreaks.length >
          this.configuration.liveAdvertisementBreakHistoryLimit
      ) {
        this.advertisementBreaks.shift();
      }

      this.configuration.callbacks.advertisementBreaksUpdated(
        this.cloneAdvertisementBreaks([advertisementBreak])
      );
    }

    return {
      ...advertisementBreak,
      advertisements: advertisementBreak.advertisements.map(
        (advertisement) => ({
          ...advertisement,
        })
      ),
    } as AdvertisementBreak<TAdvertisementAuxiliaryData>;
  }

  private mapAdvertisement(
    advert: Advert,
    id?: string
  ): Advertisement<TAdvertisementAuxiliaryData> {
    const getAuxiliaryData = (
      advert: Advert
    ): TAdvertisementAuxiliaryData | undefined => {
      const universalAdId = getUniversalAdId(advert);
      if (universalAdId) {
        return {
          campaignId: "",
          customId: universalAdId,
          goalId: "",
          sponsor: false,
        } as unknown as TAdvertisementAuxiliaryData;
      } else {
        return this.configuration.parseExtensions
          ? this.configuration.parseExtensions(advert.getExtensions())
          : parseAdInfoExtension<TAdvertisementAuxiliaryData>(
              advert.getExtensions()
            );
      }
    };

    return new Advertisement({
      id:
        id ??
        `Advertisement-${this.configuration.contentId}-${++this
          .nextAdvertisementId}`,
      name: advert.getProperty("AdTitle")?.getValue(),
      auxiliaryData: getAuxiliaryData(advert),
      clickThroughUrl: advert.getLinearCreative()?.getClickThroughUrl(),
      correlationId: advert.getIdentifier(),
      durationInSeconds: msToSeconds(advert.getDuration()),
      indexInAdvertisementBreak: advert.getSequence(),
    });
  }

  private mapAdvertisementBreak(
    adBreak: AdBreak,
    position?: number,
    session?: Session
  ): AdvertisementBreak<TAdvertisementAuxiliaryData> {
    const adBreakPositionInContentTime =
      isVodSession(session) &&
      msToSeconds(session.getContentPositionForPlayhead(adBreak.getStart()));
    const watched =
      isVodSession(session) &&
      isNumber(adBreakPositionInContentTime) &&
      isNumber(this.configuration.startTime) &&
      adBreakPositionInContentTime < this.configuration.startTime;
    const breakType = mapBreakType(adBreak.getPosition());
    const adverts = adBreak.getAdverts();
    const advertisements: Array<Advertisement<TAdvertisementAuxiliaryData>> =
      adverts.map((advert: Advert) => this.mapAdvertisement(advert));

    return new AdvertisementBreak<TAdvertisementAuxiliaryData>({
      id: `AdvertisementBreak-${this.configuration.contentId}-${++this
        .nextAdvertisementBreakId}`,
      name: "",
      correlationId: adBreak.getIdentifier(),
      active: false,
      advertisements,
      breakType,
      durationInSeconds: msToSeconds(adBreak.getDuration()),
      embedded: true,
      position: isNumber(position) ? position : msToSeconds(adBreak.getStart()),
      watched,
    });
  }

  private mapAdvertisementBreaks(session: Session) {
    if (this.configuration.live) return;

    const newAdvertisementBreaks = session
      .getAdBreaksByType(BreakType.LINEAR)
      /**
       * Note: In theory, AdBreaks that have an identifier could be handled here too - but they can only be
       *   dispatched through callbacks.advertisementBreaksUpdated when they are started - in
       *   handleAdvertisementBreakStart - because it's only then we know their correct position.
       */
      // .filter((adBreak) => (!this.configuration.live || !!adBreak.getIdentifier()) && !!adBreak.getAdverts().length)
      .filter((adBreak) => !!adBreak.getAdverts().length)
      // Only map unknown AdBreaks
      .filter((adBreak) => !this.findAdvertisementBreak(adBreak))
      .map((adBreak) =>
        this.mapAdvertisementBreak(
          adBreak,
          this.configuration.live ? this.absolutePosition : undefined,
          session
        )
      );

    if (newAdvertisementBreaks.length) {
      this.advertisementBreaks.push(...newAdvertisementBreaks);

      this.configuration.callbacks.advertisementBreaksUpdated(
        this.cloneAdvertisementBreaks(newAdvertisementBreaks)
      );
    }
  }

  public async initialize(): Promise<string> {
    const yospaceUrl = this.createYospaceUrl(this.configuration);

    this.session = await this.createYospaceSession(yospaceUrl);

    return this.stitchedUrl!;
  }

  public getAbsolutePosition(contentPosition: number): number {
    if (!isVodSession(this.session)) return contentPosition;

    return msToSeconds(
      this.session.getPlayheadForContentPosition(secondsToMs(contentPosition))
    );
  }

  public getAdvertisement():
    | Advertisement<TAdvertisementAuxiliaryData>
    | undefined {
    return this.advertisement;
  }

  public getAdvertisementBreak():
    | AdvertisementBreak<TAdvertisementAuxiliaryData>
    | undefined {
    return this.advertisementBreak;
  }

  public getAdvertisementBreaks(): Array<
    AdvertisementBreak<TAdvertisementAuxiliaryData>
  > {
    return this.cloneAdvertisementBreaks(this.advertisementBreaks);
  }

  public getAdvertisementClickThroughUrl(): string | undefined {
    return this.getAdvertisement()?.clickThroughUrl;
  }

  public getAdvertisementDuration(): number | undefined {
    return this.getAdvertisement()?.durationInSeconds;
  }

  public getAdvertisementTime(): number {
    const advert = this.session?.getCurrentAdvert();

    // If live, advert.getStart() will not be correct
    const advertisementStart = this.configuration.live
      ? this.advertisementStartedAtPosition
      : advert
      ? msToSeconds(advert.getStart())
      : undefined;

    if (!isNumber(advertisementStart)) return 0;

    return this.absolutePosition - advertisementStart;
  }

  public getContentDuration(): number {
    if (!isVodSession(this.session)) return 0;

    return msToSeconds(
      this.session.getContentPositionForPlayhead(this.session.getDuration())
    );
  }

  public getContentPosition(absolutePosition: number): number {
    if (!isVodSession(this.session)) return absolutePosition;

    return msToSeconds(
      this.session.getContentPositionForPlayhead(secondsToMs(absolutePosition))
    );
  }

  /** Tracking */

  public advertisementClicked(): void {
    this.session?.getCurrentAdvert()?.getLinearCreative()?.onClickThrough();
  }

  public id3Cue(id3Cue: Id3Cue) {
    const yospaceTimedDataObject =
      parseYospaceTimedDataObjectFromId3Cues(id3Cue);
    this.yospaceTimedDataObjects.push(yospaceTimedDataObject);
  }

  /** Matching ITracker */

  public loaded(): void {
    if (!this.session) return;

    // Loaded indicates we are ready to play, run START
    this.session.onPlayerEvent(
      PlayerEvent.START,
      secondsToMs(this.absolutePosition)
    );

    // Force pre-roll ad break to trigger before playing event
    if (!this.configuration.live) {
      this.session.onPlayheadUpdate(
        secondsToMs(this.configuration.startTime || 0)
      );
    }

    // We're not playing until playing event arrives
    this.session.onPlayerEvent(
      PlayerEvent.PAUSE,
      secondsToMs(this.absolutePosition)
    );
  }

  public buffered(): void {
    if (!this.session || !this.isBuffering) return;

    this.isBuffering = false;
    this.session.onPlayerEvent(
      PlayerEvent.CONTINUE,
      secondsToMs(this.absolutePosition)
    );
  }

  public buffering(): void {
    if (!this.session || this.absolutePosition <= 3) return;

    this.isBuffering = true;
    this.session.onPlayerEvent(
      PlayerEvent.STALL,
      secondsToMs(this.absolutePosition)
    );
  }

  public paused(): void {
    this.session?.onPlayerEvent(
      PlayerEvent.PAUSE,
      secondsToMs(this.absolutePosition)
    );
  }

  public playing(): void {
    if (this.isBuffering) {
      this.buffered();
    }

    this.session?.onPlayerEvent(
      PlayerEvent.RESUME,
      secondsToMs(this.absolutePosition)
    );
  }

  public stopped(): void {
    this.session?.onPlayerEvent(
      PlayerEvent.STOP,
      secondsToMs(this.absolutePosition)
    );
  }

  public timeUpdate(absolutePosition: number): void {
    if (!this.session) return;

    this.absolutePosition = absolutePosition;

    if (!isNaN(this.absolutePosition)) {
      this.session.onPlayheadUpdate(secondsToMs(this.absolutePosition));

      this.yospaceTimedDataObjects.forEach((timedData, index) => {
        if (!this.session) return;

        if (timedData.getPlayhead() / 1000 <= this.absolutePosition) {
          this.session.onTimedMetadata(timedData);
          this.yospaceTimedDataObjects.splice(index, 1);
        }
      });
    }
  }

  /** /Matching ITracker */

  public async reset(): Promise<void> {
    this.stopped();

    if (this.session) {
      this.session.shutdown();
    }
    this.session = undefined;

    this.absolutePosition = 0;
    this.advertisement = undefined;
    this.advertisementBreak = undefined;
    this.advertisementBreaks = [];
    this.isBuffering = false;
    this.nextAdvertisementId = -1;
    this.nextAdvertisementBreakId = -1;
    this.yospaceTimedDataObjects = [];
    this.stitchedUrl = undefined;
  }

  public async destroy(): Promise<void> {
    await this.reset();
  }
}
