import styled from "@emotion/styled";
import {
  useCastPlaybackSpec,
  useIsCasting,
  useIsCastingSupported,
} from "@telia-company/tv.web-player-cc-sender";
import {
  AccessControl,
  TPlayerStats,
  TUnfortunatelyAny,
  VideoIdType,
  WatchMode,
} from "@telia-company/tv.web-player-shared";
import * as icons from "@telia-company/tv.web-player-telia-icons";
import {
  EngineState,
  TLanguageCode,
  TPlaybackSpec,
  TPlayerConfig,
  TState,
  TUsePlayerOptions,
  usePlayer,
} from "@telia-company/tv.web-player-ui";
import { FC, useEffect, useMemo, useState } from "react";

import { getOngoingProgram, imageProxy } from "../misc/util";
import { translations } from "../mock-data/translations";

export type TPlayerOptions = {
  channelList: TUnfortunatelyAny;
  closePlayer: () => void;
  conf: TPlayerConfig;
  playback: TUsePlayerOptions["playback"];
  setEngineState: (engineState: EngineState | null) => void;
  setPlayerState: (state: null | TState) => void;
  setPlayerStats: (stats: null | TPlayerStats) => void;
};

const PlayerContainer = styled.div`
  margin: 0 0 0.5rem;
`;

const StyledBox = styled.div<{ error?: boolean }>`
  font-size: 1rem;
  background: ${({ error }) =>
    error ? "rgba(135, 7, 7, 0.5)" : "rgba(255, 255, 255, 0.2)"};
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid
    ${({ error }) =>
      error ? "rgba(135, 7, 7, 0.9)" : "rgba(255, 255, 255, 0.5)"};
`;

const design = {
  customStyles: {
    borderRadius: "0.5em",
    brandColor: "#EF7215",
    brandColorDark: "#883000",
    buttonBorderRadius: "1.8em",
    channelListItemBackground: "#382a05",
    progressBarColor: "#984307",
    progressBarColorDark: "#883000",
  },
  icons,
};

const getMockSport = (n: number) => ({
  endTime: Date.now() + 40000,
  id: Math.random().toString(),
  image: "http://placehold.it/160x90",
  playback: {
    playbackSpec: {
      accessControl: AccessControl.SUBSCRIPTION,
      videoId: Math.random().toString(),
      videoIdType: VideoIdType.MEDIA,
      watchMode: WatchMode.LIVE,
    },
  },
  startTime: Date.now() + 20000,
  subtitle: "19:00 - 23:59",
  title: `hej${n}`,
  type: "live-event",
});

const getMockEpisode = (
  n: number
): {
  // unique id, e.g. videoId
  id: string;
  image: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  playback?: any;
  // continue watching progress
  progress: number; // 0 to 1
  subtitle: null | string;
  title: string;
  type: "series";
} => ({
  id: Math.random().toString(),
  image: "http://placehold.it/160x90",
  playback: {
    playbackSpec: {
      accessControl: AccessControl.SUBSCRIPTION,
      videoId: Math.random().toString(),
      videoIdType: VideoIdType.MEDIA,
      watchMode: WatchMode.ONDEMAND,
    },
  },
  progress: 0.3,
  subtitle: "Lite mer text",
  title: `episoood ${n}`,
  type: "series",
});

const mockSports = [
  getMockSport(1),
  getMockSport(2),
  getMockSport(3),
  getMockSport(4),
  {
    endTime: Date.now() + 40000,
    image: "http://placehold.it/160x90",
    startTime: Date.now() + 20000,
    subtitle: "19:00 - 23:59",

    title: `cust`,
    type: "live-event",
  },
  getMockSport(5),
  getMockSport(6),
  getMockSport(7),
  getMockSport(8),
  getMockSport(9),
];

const mockSeries = {
  1: [
    getMockEpisode(1),
    getMockEpisode(2),
    getMockEpisode(3),
    getMockEpisode(4),
    getMockEpisode(5),
  ],
  10: [getMockEpisode(1)],
  11: [getMockEpisode(1)],
  12: [getMockEpisode(1)],
  13: [getMockEpisode(1)],
  14: [getMockEpisode(1)],
  15: [getMockEpisode(1)],
  16: [getMockEpisode(1)],
  17: [getMockEpisode(1)],
  18: [getMockEpisode(1)],
  19: [getMockEpisode(1)],
  2: [
    getMockEpisode(1),
    getMockEpisode(2),
    getMockEpisode(3),
    getMockEpisode(4),
    getMockEpisode(5),
    getMockEpisode(6),
    getMockEpisode(7),
    getMockEpisode(8),
    getMockEpisode(9),
    getMockEpisode(10),
  ],
  20: [getMockEpisode(1)],
  21: [getMockEpisode(1)],
  22: [getMockEpisode(1)],
  23: [getMockEpisode(1)],
  24: [getMockEpisode(1)],
  3: [getMockEpisode(1)],
  4: [getMockEpisode(1)],
  5: [getMockEpisode(1)],
  6: [getMockEpisode(1)],
  7: [getMockEpisode(1)],
  8: [getMockEpisode(1)],
  9: [getMockEpisode(1)],
};

const user = {
  fallbackAudioLanguage: "en" as keyof TLanguageCode,
  preferredAudioLanguage: "sv" as keyof TLanguageCode,
  preferredSubtitleLanguage: "sv" as keyof TLanguageCode,
  // stillWatching: {
  //   idleTimeVodMs: 6000,
  //   idleTimeLinearMs: 4000,
  //   gracePeriodMs: 5000,
  // },
};

const npvrEvent = {
  currentProgram: {
    endTime: Date.now() + 40000,
    startTime: Date.now() + 20000,
    subtitle: "hej",
    title: "Some Kind of Npvr",
  },
  iconOnDarkBg: "http://placehold.it/20x20",
  iconOnLightBg: "http://placehold.it/20x20",
  id: "someNpvrId",
  label: {
    backgroundColor: "#984307",
    text: "RECORD TO WATCH",
    textColor: "#ffffff",
  },
  modalMetadata: {
    body:
      "You need to record this program to watch it. \n" +
      "Press “record and watch” to start it from the beginning.",
    subtitle: {
      firstRow: "Some subtitle info",
      secondRow: "17:00 - 18:00",
    },
    title: "The Modal Title for NPVR",
  },
  playback: {
    playbackSpec: {
      accessControl: "NPVR",
      videoId: "asd",
      videoIdType: "MEDIA",
      watchMode: "STARTOVER",
    },
  },
  recordButton: {
    buttonText: "Spela in programmet",
    icon: icons.IconPlay,
    onClick: () =>
      new Promise((_res, rej) => {
        setTimeout(() => {
          // Test Rejection
          rej();
          // Test Success Case
          //          res({
          //            playbackSpec: {
          //              videoId: "m-c4e13ef0c5ba82336f9d",
          //              videoIdType: "MEDIA",
          //              accessControl: "SUBSCRIPTION",
          //              watchMode: "ONDEMAND"
          //            }
          //          } as TPlaybackConfig)
        }, 2000);
      }),
    type: "primary",
  },
  title: "Test Npvr Event",
  type: "npvr",
};

export const Player: FC<TPlayerOptions> = ({
  channelList,
  closePlayer,
  conf,
  playback: incomingPlayback,
  setEngineState,
  setPlayerState,
  setPlayerStats,
}) => {
  const [restrictions] = useState({
    // maxBandwidth: 5242880 // 5MB
  });

  const playback = useMemo(
    () => ({
      ...incomingPlayback,
      // Playback Context indicates if a STARTOVER asset
      autoplay: true,
      npvrInfo: {
        media: {
          isRecordable: true,
          metadata: {
            id: "mock-id-1",
            title: "mock recording title",
          },
        },
      },
      // belongs to a CHANNEL or a LIVE event
      playbackContext: {
        ...(incomingPlayback.playbackSpec.watchMode === WatchMode.STARTOVER
          ? {
              ...incomingPlayback.playbackSpec,
              // DEV: Force LIVE watch mode for STARTOVER context to test jumping
              watchMode: WatchMode.LIVE,
            }
          : incomingPlayback.playbackSpec),
      } as TPlaybackSpec,
      selectedAssetAudioLanguage: "sv" as keyof TLanguageCode,
    }),
    [incomingPlayback]
  );

  if (localStorage.getItem("savePlaybackSpec") === "true") {
    localStorage.setItem(
      "lastPlaybackSpec",
      JSON.stringify(playback.playbackSpec)
    );
  } else {
    localStorage.removeItem("lastPlaybackSpec");
  }

  const castPlaybackSpec = useCastPlaybackSpec();
  const isCastingSupported = useIsCastingSupported();
  const isCasting = useIsCasting();

  const mockChannels = useMemo(() => {
    const favoriteChannelIds = ["1", "4"];
    return [
      npvrEvent,
      ...channelList.map((n: TUnfortunatelyAny) => ({
        currentProgram: getOngoingProgram(n.programs),
        iconOnDarkBg: n.icons.dark,
        iconOnLightBg: n.icons.light,
        id: n.id,
        isFavorite: favoriteChannelIds.indexOf(n.id) > -1,
        playback: {
          playbackSpec: {
            accessControl: AccessControl.SUBSCRIPTION,
            videoId: n.id,
            videoIdType: VideoIdType.CHANNEL,
            watchMode: WatchMode.LIVE,
          },
        },
        title: n.name || "unknown channel",
        type: "channel",
      })),
    ];
  }, [channelList]);

  const callbacks = useMemo(
    (): TUsePlayerOptions["callbacks"] => ({
      backButtonCallback: closePlayer,
      ccButtonCallback: isCastingSupported
        ? async ({
            currentTime,
            playbackSpec,
            selected,
            series,
            title,
          }): Promise<void> => {
            await castPlaybackSpec({
              currentTime,
              playbackSpec,
              selected: {
                assetLanguage: selected?.assetLanguage,
                audio: selected?.audio || undefined,
                text: selected?.text || undefined,
              },
              series,
              title,
            });
            if (isCasting) {
              closePlayer();
            } else {
              throw "cast cancelled";
            }
          }
        : undefined,
      // eslint-disable-next-line no-console
      pipButtonCallback: () => console.log("PiP CLICKED"),
    }),
    [closePlayer, isCastingSupported, castPlaybackSpec, isCasting]
  );

  const [selectedSeason, setSelectedSeason] = useState(1);

  const metadata = useMemo<TUsePlayerOptions["metadata"]>(
    () => ({
      getRecordingCbs: {
        addRecording: () => {
          // eslint-disable-next-line no-console
          console.log("mock adding recording");
          return new Promise((res /* rej */) => {
            // setTimeout(() => rej(new Error("recording failed!")), 1000);
            setTimeout(() => res(), 1000);
          });
        },
        removeRecording: () => {
          // eslint-disable-next-line no-console
          console.log("mock removing recording");
          return new Promise((res) => {
            setTimeout(() => res(), 600);
          });
        },
      },
      getRelatedContent: async (playbackSpec) => {
        const isChannel = playbackSpec.videoIdType === "CHANNEL";
        const isSportEvent = true; /* isLiveSportEvent(playbackSpec) */
        const isVodSeries =
          playbackSpec.watchMode === "ONDEMAND"; /* && isSeries(playbackSpec) */

        if (isChannel) {
          return Promise.resolve({
            closeButtonIcon: icons.IconCloseOverlay,
            closeButtonText: translations.closeRelatedContent,
            getPanelData: ({ limit, offset }) =>
              new Promise((res) => {
                setTimeout(
                  () =>
                    res({
                      items: mockChannels.slice(offset, offset + limit),
                    }),
                  200
                );
              }),
            headerText: "Dina Kanaler",
            indexOfCurrentItem: mockChannels.findIndex(
              (i) => i.playback.playbackSpec.videoId === playbackSpec.videoId
            ),
            openButtonIcon: icons.IconRelatedContent,
            openButtonText: "Kanaler",
            totalItems: mockChannels.length,
            type: "epg",
          });
        }

        if (isVodSeries) {
          return Promise.resolve({
            closeButtonIcon: icons.IconCloseOverlay,
            closeButtonText: translations.closeRelatedContent,
            getPanelData: ({ limit, offset, season }) => {
              setSelectedSeason(season);

              return new Promise((res) => {
                setTimeout(
                  () =>
                    res({
                      items: mockSeries[season as 1 | 2 | 3].slice(
                        offset,
                        offset + limit
                      ),
                    }),
                  200
                );
              });
            },
            headerText: "Avsnitt",
            indexOfCurrentItem: 0,
            openButtonIcon: icons.IconRelatedContent,
            openButtonText: "Avsnitt",
            seasonOfCurrentItem: 1,
            seasons: [
              { episodes: mockSeries[1].length, season: 1 },
              { episodes: mockSeries[2].length, season: 2 },
              { episodes: mockSeries[3].length, season: 3 },
              { episodes: mockSeries[1].length, season: 4 },
              { episodes: mockSeries[2].length, season: 5 },
              { episodes: mockSeries[3].length, season: 6 },
              { episodes: mockSeries[1].length, season: 7 },
              { episodes: mockSeries[2].length, season: 8 },
              { episodes: mockSeries[3].length, season: 9 },
              { episodes: mockSeries[1].length, season: 10 },
              { episodes: mockSeries[2].length, season: 11 },
              { episodes: mockSeries[3].length, season: 12 },
              { episodes: mockSeries[1].length, season: 13 },
              { episodes: mockSeries[2].length, season: 14 },
              { episodes: mockSeries[3].length, season: 15 },
              { episodes: mockSeries[1].length, season: 16 },
              { episodes: mockSeries[2].length, season: 17 },
              { episodes: mockSeries[3].length, season: 18 },
              { episodes: mockSeries[1].length, season: 19 },
              { episodes: mockSeries[2].length, season: 20 },
              { episodes: mockSeries[3].length, season: 21 },
              { episodes: mockSeries[1].length, season: 22 },
              { episodes: mockSeries[2].length, season: 23 },
              { episodes: mockSeries[3].length, season: 24 },
            ],
            totalItems:
              mockSeries[
                selectedSeason as
                  | 1
                  | 10
                  | 11
                  | 12
                  | 13
                  | 14
                  | 15
                  | 16
                  | 17
                  | 18
                  | 19
                  | 2
                  | 20
                  | 21
                  | 22
                  | 23
                  | 24
                  | 3
                  | 4
                  | 5
                  | 6
                  | 7
                  | 8
                  | 9
              ].length,
            type: "series",
          });
        }

        if (isSportEvent) {
          return Promise.resolve({
            closeButtonIcon: icons.IconCloseOverlay,
            closeButtonText: translations.closeRelatedContent,
            getPanelData: ({ limit, offset /* windowStartAt */ }) =>
              new Promise((res) => {
                setTimeout(
                  () =>
                    res({
                      indexOfCurrentItem: 5,
                      // @ts-expect-error mock sports may have the wrong type
                      items: mockSports
                        .slice(0, 7)
                        .slice(offset, offset + limit),

                      totalItems: 7,
                    }),
                  200
                );
              }),
            headerText: "Mer Sport",
            indexOfCurrentItem: 0,
            openButtonIcon: icons.IconRelatedContent,
            openButtonText: "Super Sport",
            totalItems: mockSports.length,
            type: "live-event",
          });
        }

        return null;
      },
    }),
    [mockChannels, selectedSeason]
  );

  const {
    currentlyPlaying,
    debug,
    destroyPlayer,
    error,
    state,
    stats,
    VideoWrapper,
  } = usePlayer({
    callbacks,
    conf,
    design,
    features: {
      disableAds: localStorage.getItem("disableAds") === "true",
      disableDVR: localStorage.getItem("disableDVR") === "true",
      disableHlsjs: localStorage.getItem("disableHlsjs") === "true",
      enableUHD: localStorage.getItem("enableUHD") === "true",
      thumbnailScrubbing: true,
      timers: localStorage.getItem("timers") === "true",
    },
    imageProxy,
    metadata,
    playback,
    restrictions,
    translations,
    user,
  });

  useEffect(() => {
    if (!stats) return;
    setPlayerStats(stats);
  }, [stats, setPlayerStats]);

  useEffect(() => {
    setPlayerState(debug);
  }, [debug, setPlayerState]);

  useEffect(() => {
    setEngineState(state);
  }, [setEngineState, state, closePlayer]);

  useEffect(() => destroyPlayer, [destroyPlayer]);

  useEffect(() => {
    if (!error) return;
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  if (error) {
    return (
      <StyledBox error>Error: {error.code} (see console for details)</StyledBox>
    );
    // return <div>{error && <pre>{JSON.stringify(error, null, 2)}</pre>}</div>;
  }

  return (
    <>
      <StyledBox>
        <strong>Currently Playing:</strong>
        {` ${currentlyPlaying.videoId} | ${currentlyPlaying.videoIdType} | ${currentlyPlaying.accessControl} | ${currentlyPlaying.watchMode}`}
      </StyledBox>
      <PlayerContainer>{VideoWrapper}</PlayerContainer>
    </>
  );
};
