import type { XmlNode } from "@yospace/admanagement-sdk/types/Core/XmlNode";
import { IYospacePlaybackFeatureAdvertisementAuxiliaryData } from "../Types";
import { AdvertisementAuxiliaryData } from "../dtos";

const findNode = (name: string, startNode: XmlNode): XmlNode | undefined => {
  if (startNode.getName() === name) return startNode;

  const attributes = startNode.getAttributes();
  const nameAttribute = attributes.get("name");
  const typeAttribute = attributes.get("type");
  if (nameAttribute === name || typeAttribute === name) return startNode;

  const children = startNode.getChildren();
  for (let c in children) {
    if (!children.hasOwnProperty(c)) continue;

    const foundNode = findNode(name, children[c]);
    if (foundNode) return foundNode;
  }
};

export const getDataFromAdInfo = (
  adInfo?: XmlNode
): IYospacePlaybackFeatureAdvertisementAuxiliaryData => ({
  campaignId: adInfo?.getAttributes()?.get("cid") || "",
  customId: adInfo?.getAttributes()?.get("customaid") || "",
  goalId: adInfo?.getAttributes()?.get("gid") || "",
  sponsor: adInfo?.getAttributes()?.get("variant") === "BUMPER",
});

export const getVastAdExtensionData = (
  extensionsNode: XmlNode
): IYospacePlaybackFeatureAdvertisementAuxiliaryData => {
  // Look explicitly for a videoplaza extension with AdInfo
  const videoplazaExtension: XmlNode | undefined = findNode(
    "Videoplaza",
    extensionsNode
  );

  const nentExtension: XmlNode | undefined = findNode(
    "FreeWheel",
    extensionsNode
  );

  const nentCustomIdNode: XmlNode | undefined =
    nentExtension && findNode("_fw_4AID", nentExtension);
  const nentCustomId = nentCustomIdNode?.getInnerText();

  // Extract videoplaza AdInfo
  const videoplazaAdInfo: XmlNode | undefined =
    videoplazaExtension && findNode("AdInfo", videoplazaExtension);
  if (videoplazaAdInfo) {
    return getDataFromAdInfo(videoplazaAdInfo);
  }

  // Look for any AdInfo
  const adInfo: XmlNode | undefined = findNode("AdInfo", extensionsNode);

  // Extract AdInfo
  const adInfoData = getDataFromAdInfo(adInfo);

  // TMPD-3366, override customId
  if (nentCustomId) {
    adInfoData.customId = nentCustomId.trim();
  }

  return adInfoData;
};

export const parseAdInfoExtension = <
  TAdvertisementAuxiliaryData extends AdvertisementAuxiliaryData
>(
  extensionsNode: XmlNode | null
): TAdvertisementAuxiliaryData | undefined => {
  if (!extensionsNode) return;
  const adInfo: XmlNode | undefined = findNode("AdInfo", extensionsNode);

  return Object.fromEntries(adInfo?.getAttributes() ?? new Map());
};
