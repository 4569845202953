import styled from "@emotion/styled";
import {
  useCastPlaybackSpec,
  useIsCasting,
} from "@telia-company/tv.web-player-cc-sender";
import {
  TPlayerStats,
  TUnfortunatelyAny,
} from "@telia-company/tv.web-player-shared";
import {
  EngineState,
  playerUiVersion,
  TState,
} from "@telia-company/tv.web-player-ui";
import React, { FC, useEffect, useState } from "react";

import { useChannelZap } from "../hooks/channel-zap.hook";
import { useChannelsPanel } from "../hooks/channels.hook";
import { Panel, useContentPanels } from "../hooks/continue-watching.hook";
import { BrandColor, BrandColorDark } from "../misc/constants";
import {
  togglePlayerAction,
  useRefAppDispatch,
  useRefAppState,
} from "../reducer/ref.reducer";
import { PlayerState } from "../stats/player-state";
import { Stats } from "../stats/stats";
import { CustomContentForm } from "./custom-content-form";
import { Player } from "./Player";
import { Row } from "./row";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  height: 100%;
  min-height: 100%;
`;

const Column = styled.div`
  height: 100%;
`;

const CheckboxItem = styled.div`
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0 0 0.5rem;
  background: ${BrandColorDark};
  border: 1px solid ${BrandColor};
  display: flex;
  align-items: center;
`;
const VersionItem = styled.div`
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0 0 0.5rem;
  background: ${BrandColorDark};
  border: 1px solid ${BrandColor};
`;

export const MainContainer: FC<{ channelList: TUnfortunatelyAny[] }> = ({
  channelList,
}) => {
  const [playerStats, setPlayerStats] = useState<null | TPlayerStats>(null);
  const [playerState, setPlayerState] = useState<null | TState>(null);
  const [engineState, setEngineState] = useState<EngineState | null>(null);
  const [savePlaybackSpec, setSavePlaybackSpec] = useState(
    localStorage.getItem("savePlaybackSpec") ?? false
  );
  const [useTimers, setUseTimers] = useState(
    localStorage.getItem("timers") ?? false
  );
  const [disableAds, setDisableAds] = useState(
    localStorage.getItem("disableAds") ?? false
  );
  const [disableHlsjs, setDisableHlsjs] = useState(
    localStorage.getItem("disableHlsjs") ?? false
  );
  const [disableDVR, setDisableDVR] = useState(
    localStorage.getItem("disableDVR") ?? false
  );
  const [enableUHD, setEnableUHD] = useState(
    localStorage.getItem("enableUHD") ?? false
  );
  const { playback, playerConf, showPlayer } = useRefAppState();
  const dispatch = useRefAppDispatch();

  const [cwItems, setCwItems] = useState<Panel>([]);
  const [mlItems, setMlItems] = useState<Panel>([]);
  const [channels, setChannels] = useState<Panel>([]);
  const [pendingCast, setPendingCast] = useState(false);

  const isCasting = useIsCasting();
  const castPlaybackSpec = useCastPlaybackSpec();

  const zap = useChannelZap();
  useContentPanels({ setCwItems, setMlItems });
  useChannelsPanel({ setChannels });

  const handleSavePlaybackSpec = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { checked },
    } = event;
    setSavePlaybackSpec(checked.toString());
    localStorage.setItem("savePlaybackSpec", checked.toString());
    if (!checked) {
      localStorage.removeItem("lastPlaybackSpec");
    }
  };

  const handleToggleTimers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    setUseTimers(checked);
    localStorage.setItem("timers", checked.toString());
    if (!checked) {
      localStorage.removeItem("timers");
    }
  };

  const handleToggleAds = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    setDisableAds(checked);
    localStorage.setItem("disableAds", checked.toString());
    if (!checked) {
      localStorage.removeItem("disableAds");
    }
  };

  const handleToggleDVR = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    setDisableDVR(checked);
    localStorage.setItem("disableDVR", checked.toString());
    if (!checked) {
      localStorage.removeItem("disableDVR");
    }
  };
  const handleToggleHlsjs = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    setDisableHlsjs(checked);
    localStorage.setItem("disableHlsjs", checked.toString());
    if (!checked) {
      localStorage.removeItem("disableHlsjs");
    }
  };

  const handleToggleUHD = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = event;
    setEnableUHD(checked);
    localStorage.setItem("enableUHD", checked.toString());
    if (!checked) {
      localStorage.removeItem("enableUHD");
    }
  };

  useEffect(() => {
    if (!isCasting || !playback || pendingCast) return;
    setPendingCast(true);
    castPlaybackSpec({
      currentTime: playback.playbackSpec.watchMode === "LIVE" ? Infinity : 0,
      playbackSpec: playback.playbackSpec,
    })
      .catch((_e) => {})
      .then(() => {
        setPendingCast(false);
      });
  }, [playback, isCasting, castPlaybackSpec, pendingCast]);

  return (
    <GridWrapper>
      <Column>
        <CheckboxItem>
          <input
            checked={savePlaybackSpec === "true"}
            onChange={handleSavePlaybackSpec}
            type="checkbox"
          />
          &nbsp;Save playbackSpec
        </CheckboxItem>
        <CheckboxItem>
          <input
            checked={useTimers.toString() === "true"}
            onChange={handleToggleTimers}
            type="checkbox"
          />
          &nbsp;Use loading timers
        </CheckboxItem>
        <CheckboxItem>
          <input
            checked={disableAds.toString() === "true"}
            onChange={handleToggleAds}
            type="checkbox"
          />
          &nbsp;Disable Ads
        </CheckboxItem>
        <CheckboxItem>
          <input
            checked={disableDVR.toString() === "true"}
            onChange={handleToggleDVR}
            type="checkbox"
          />
          &nbsp;Disable DVR
        </CheckboxItem>
        <CheckboxItem>
          <input
            checked={disableHlsjs.toString() === "true"}
            onChange={handleToggleHlsjs}
            type="checkbox"
          />
          &nbsp;Disable Hls.js
        </CheckboxItem>
        <CheckboxItem>
          <input
            checked={enableUHD.toString() === "true"}
            onChange={handleToggleUHD}
            type="checkbox"
          />
          &nbsp;Enable UHD (Safari)
        </CheckboxItem>
        <VersionItem>Player Version: {playerUiVersion}</VersionItem>
        {engineState && engineState !== EngineState.STOPPED && playerState && (
          <>
            <PlayerState playerState={playerState} />
            {playerState?.videoElement && playerStats && (
              <Stats
                playerStats={playerStats}
                videoElement={playerState.videoElement}
              />
            )}
          </>
        )}
      </Column>
      <Column>
        {playback && playerConf && showPlayer && !isCasting && (
          <Player
            channelList={channelList}
            closePlayer={() => {
              dispatch(togglePlayerAction(false));
              setPlayerStats(null);
              setPlayerState(null);
              setEngineState(null);
            }}
            conf={playerConf}
            playback={playback}
            setEngineState={setEngineState}
            setPlayerState={setPlayerState}
            setPlayerStats={setPlayerStats}
          />
        )}
        <CustomContentForm />

        <Row items={cwItems} title="Continue Watching" />
        <Row items={mlItems} title="My List" />
        <Row items={channels} title="Channels" />

        {zap}
      </Column>
    </GridWrapper>
  );
};
